import React, { useState } from 'react'
import Check from '@material-ui/icons/Check'
import { makeStyles } from '@material-ui/core/styles'
import { Link }  from 'gatsby'
import Button from './Button'
import H5 from './H5'
import H6 from './H6'
import Modal from './PromotionModal'
import LigamosParaVoceForm from '../components/LigamosParaVoceForm'
import EnvieSuaMensagem from '../components/EnvieSuaMensagem'
import { btnContainedNav, btnFooter } from '../components/styles/ContainedButton.styles'
import { btnOutlinedNav } from '../components/styles/OutlineButton.styles'
import { contentDesc } from '../components/styles/InternalPage.styles'
import { validateRequiredEmail } from '../utils/validations'
import { 
  termosBox 
} from '../components/styles/Contact.styles'

const useModalStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: '#ff5800',
    padding: '50px 50px',
    borderRadius: 20,
    boxShadow: '-4px 10px 20px 0px rgba(0,0,0,0.12)',
  },
}))

const callTo = [
  {
    label: 'Nome',
    name: 'your-name',
    type: 'text',
    required: true
  },
  {
    label: 'Telefone',
    name: 'your-phone',
    type: 'text',
    required: true,
    mask: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  },
  {
    label: (
      <span css={termosBox}>
        Ao continuar, indica que aceita os
        {" "}
        <Link to="/termos-de-servico" style={{ textDecoration: 'underline' }}>
          Termos de Serviço
        </Link>
        {" "}
        e a nossa
        {" "}
        <Link to="/politica-de-privacidade" style={{ textDecoration: 'underline' }}>
          Política de Privacidade.
        </Link>
      </span>
    ),
    name: 'your-consent',
    type: 'checkbox',
    required: true
  },
]

const sendMensagem = [
  {
    label: 'Nome',
    name: 'your-name',
    type: 'text',
    required: true
  },
  {
    label: 'Email',
    name: 'your-email',
    type: 'email',
    required: true,
    validate: validateRequiredEmail,
  },
  {
    label: 'Telefone',
    name: 'your-phone',
    type: 'text',
    required: true,
    mask: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  },
  {
    label: 'Mensagem',
    name: 'your-message',
    type: 'text',
    required: true
  },
  {
    label: (
      <span css={termosBox}>
        Ao continuar, indica que aceita os
        {" "}
        <Link to="/termos-de-servico" style={{ textDecoration: 'underline' }}>
          Termos de Serviço
        </Link>
        {" "}
        e a nossa
        {" "}
        <Link to="/politica-de-privacidade" style={{ textDecoration: 'underline' }}>
          Política de Privacidade.
        </Link>
      </span>
    ),
    name: 'your-consent',
    type: 'checkbox',
    required: true
  },
]

const SentMessage = ({
  onClick
}) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 30
        }}
      >
        <div
          style={{
            width: 50,
            height: 50,
            backgroundColor: '#7ed321',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Check
            style={{
              color: 'white',
              flexShrink: 0,
              fontSize: 36
            }}
          />
        </div>
      </div>
      <H6
        selected
        on='onSurface'
        variant='highEmphasis'
        align='center'
        mb={[5]}
      >
        Agradecemos seu<br />
        contato! Retornaremos<br />
        em breve.
      </H6>
      <Button
        schema='white'
        outlined
        selected
        variant='base'
        on='onSurface'
        width={['100%']}
        maxWidth={['210px']}
        style={{
          display: 'flex',
          border: '1px solid rgba(0, 0, 0, 0.12)',
          margin: '0 auto'
        }}
        onClick={onClick}
      >
        Fechar
      </Button>
    </>
  )
}

const CallMessage = ({
  onClick
}) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 30
        }}
      >
        <div
          style={{
            width: 50,
            height: 50,
            backgroundColor: '#7ed321',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Check
            style={{
              color: 'white',
              flexShrink: 0,
              fontSize: 36
            }}
          />
        </div>
      </div>
      <H6
        selected
        on='onSurface'
        variant='highEmphasis'
        align='center'
        mb={[5]}
      >
        Ligaremos em<br />
        breve para você.<br />
        Obrigado!
      </H6>
      <Button
        schema='white'
        outlined
        selected
        variant='base'
        on='onSurface'
        width={['100%']}
        maxWidth={['210px']}
        style={{
          display: 'flex',
          border: '1px solid rgba(0, 0, 0, 0.12)',
          margin: '0 auto'
        }}
        onClick={onClick}
      >
        Fechar
      </Button>
    </>
  )
}

const WannaBuyModal = ({
  visible,
  onClose
}) => {
  const [status, setStatus] = useState('default')
  const onCloseModal = () => {
    setStatus('default')
    onClose()
  }
  const classes = useModalStyles()
  const statuses = {
    default: (
      <div className={classes.paper}>
        <div
          style={{
            fontSize: 24,
            fontWeight: 600,
            lineHeight: 1.08,
            letterSpacing: 0.31,
            color: 'rgba(255, 255, 255, 0.8)',
            marginBottom: 16,
          }}
        >
          PROMOÇÃO<br /> 
          RELÂMPAGO!
        </div>
        <div
          style={{
            fontSize: 17,
            fontWeight: 600,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 0.23,
            color: 'rgba(0,0,0,0.87)',
            backgroundColor: '#fff',
            marginBottom: 8,
            width: 223,
            padding: 5
          }}
        >
          Servidor dedicado com 
        </div>
        <div
          style={{
            fontSize: 17,
            fontWeight: 600,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 0.23,
            color: 'rgba(0,0,0,0.87)',
            backgroundColor: '#fff',
            marginBottom: 16,
            padding: 5
          }}
        >
          ativação em até 60 minutos
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div
            css={contentDesc}
            style={{
              marginBottom: 30
            }}
          >
            {false && <div>
              <p><span>- Single Xeon 4-Core E3-1230 v5 3.4Ghz w/HT</span></p>
              <p><span>- 16GB DDR4 RAM</span></p>
              <p><span>- 1x 1TB HDD</span></p>
              <p><span>- CentOS 7 (64bit)</span></p>
              <p><span>- IP/29</span></p>
              <p><span>- Banda 100Mbps (ilimitado)</span></p>
            </div>}
            <div
              style={{
                textAlign: 'left'
              }}
            >
              {false && <H5
                selected
                on='onSurface'
                variant='highEmphasis'
                mt={[4]}
              >
                <span style={{ marginRight: 4 }}>R$ </span> 1.800<span>,00<b>/mês</b></span>
              </H5>}
              <H6
                // mt={[2]}
                style={{
                  justifyContent: 'flex-start'
                }}
              >
                <span style={{ marginRight: 4 }}>R$ </span> 487<span>,80<b>/mês</b></span>
              </H6>
              <p style={{ fontWeight: 'bold' }}>+ segundo mês grátis</p>
              <p style={{ fontSize: 11 }}>*Promoção válida enquanto durar o estoque.</p>
            </div>
          </div>
          <div 
            css={contentDesc}
            style={{
              marginBottom: 30
            }}
          >
            <p><span>Quer saber mais sobre a configuração desse servidor? Converse com nossos consultores.</span></p>
          </div>
          <div
            css={btnFooter}
            style={{
              maxWidth: '210px',
              display: 'flex',
              marginBottom: 10,
              justifyContent: 'center'
            }}
            onClick={() => {setStatus('needHelp')}}
          >
            RECEBER DETALHES
          </div>
        </div>
      </div>
    ),
    sent: (
      <SentMessage
        onClick={onCloseModal}
      />
    ),
    call: (
      <CallMessage
        onClick={onCloseModal}
      />
    ),
    needHelp: (
      <div style={{ backgroundColor: '#fff', padding: '50px 16px', borderRadius: 20, boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)' }}>
        <H6
          selected
          on='onSurface'
          variant='highEmphasis'
          align='center'
          mb={[4]}
        >
          Fale agora com<br /> nossos especialistas!
        </H6>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div
            css={btnContainedNav}
            style={{
              maxWidth: '210px',
              display: 'flex',
              marginBottom: 8,
            }}
            onClick={() => {setStatus('callForm')}}
          >
            ligamos pra você
          </div>
          <div
            css={btnOutlinedNav}
            style={{
              maxWidth: '210px',
              display: 'flex',
              justifyContent: 'center'
            }}
            onClick={() => {setStatus('messageForm')}}
          >
            contato
          </div>
        </div>
      </div>
    ),
    callForm: (
      <div style={{ backgroundColor: '#fff', padding: '50px 16px', borderRadius: 20, boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)' }}>
        <H6
          selected
          on='onSurface'
          variant='highEmphasis'
          align='center'
          mb={[4]}
        >
          Ligamos pra Você
        </H6>
        <LigamosParaVoceForm
          fields={callTo}
          formId={4371}
          submitText='Enviar'
        />
      </div>
    ),
    messageForm: (
      <div style={{ backgroundColor: '#fff', padding: '50px 16px', borderRadius: 20, boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)' }}>
        <H6
          selected
          on='onSurface'
          variant='highEmphasis'
          align='center'
          mb={[4]}
        >
          Envie sua Mensagem
        </H6>
        <EnvieSuaMensagem
          fields={sendMensagem}
          formId={4372}
          submitText='Enviar'
        />
      </div>
    )
  }

  return (
    <Modal
      open={visible}
      hasArrowBack={['needHelp', 'callForm', 'messageForm'].includes(status)}
      hideIcons={status === 'sent'}
      onClose={onCloseModal}
      onBack={() => setStatus('default')}
      style={{
        zIndex: 2000,
      }}
    >
      {statuses[status]}
    </Modal>
  )
}

export default WannaBuyModal
